<template>
  <div class="container">
    <div class="header-title">
      <h3><strong>Project Sizak</strong></h3>
    </div>
    <div class="txtframe txtwrap_left">
      <h4 class="bold-text">New Contemporary Photography in Korea</h4>
      <p>
        'SIZAK’은 중앙대학교의 순수사진 전공(지도교수: 천경우) 졸업생, 대학원생을 중심으로 한 젊은 작가들의 작품들을 소개하는 포트폴리오 플랫폼이다. 여기에 선별되어 소개되는 작가들은 사진의 본질에 대한 연구와 더불어 협업, 참여, 확장된 개념으로서의 새로운 사진작업을 탐구하고 있다. 이들은 국내외 현대 예술 현장에서 실험적인 프로젝트들을 수행하며 다양한 ‘시작’을 시도하고 있다.
        <br/>
        ‘SIZAK’ is the name of a loose grouping of young photography artists who have graduated or studying fine art photography at Chung-Ang University (academic advisor: Prof. Kyungwoo Chun). In 1964, Chung-Ang University established the school of photography for the first time in Korea. The graduates of this school have gone on to take leading roles in the Korean contemporary photography.
        <br/>
        <span>*‘Sizak’ in Korean has a double meaning of ‘Beginning’ and ‘Study’.</span>
      </p>
      <br/>
      <span>Contact: <a href="mailto: sizak.photo@gmail.com" target="_blank" style="font-weight: 700;"> sizak.photo@gmail.com</a></span>
      <br/><span class="header-title">Imprint</span>
      <br/><br/>
      <h4 class="bold-text">Project SIZAK</h4>
      <p>
        New Photography in Korea. All text and images on this website are protected by copyright.<br/>
        Any use or reproduction, in whole or in part, requires prior approval.<br/>
        Seoul, Korea, 2016
      </p>
      <br/>
      <span>Build&Operate: Taejun Yun 윤태준 <span><a href="mailto: taejun.foto@gmail.com" target="_blank" style="font-weight: 700;"> taejun.foto@gmail.com</a></span></span>
      <br/>
      <br/>
      <div class="border"></div>
      <br/>
      <!-- 헉과 홈페이지 추가 -->
       <h4>중앙대학교 공연영상창작학부 사진전공</h4>
      <a href="http://cauphoto.co.kr/" target="_blank">cauphoto.co.kr</a>
    </div>
  </div>
</template>

<script setup>


</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.txtframe {
  flex: 2 1 auto;
  width: 70vw;
}

.header-title {
  margin-bottom: 0.8em;
}

.bold-text {
  /* font-weight: 600; */
}

@media (max-width: 768px) {
  .txtframe {
    width: 100%; /* 모바일 화면에 맞게 전체 너비를 차지하도록 설정 */
  }
}
</style>
